<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col sm="12">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('old password')}" label="Old Password">
							<b-form-input type="password" 
								v-model="models.old_password" 
								v-validate="'required'"
								data-vv-name="old password">
							</b-form-input>
							<div v-if="errors.has('old password')">
								<p class="text-danger">{{ errors.first('old password') }}</p>
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('new password')}" label="New Password">
							<b-form-input 
								type="password" 
								v-model="models.new_password" 
								v-validate="'required|min:8'"
								data-vv-name="new password">
							</b-form-input>
							<div v-if="errors.has('new password')">
								<p class="text-danger">{{ errors.first('new password') }}</p>
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('password confirmation')}"
							label="Password Confirmation">
							<b-form-input 
								type="password" 
								v-model="models.password_confirmation" 
								v-validate="'required'"
								data-vv-name="password confirmation">
							</b-form-input>
							<div v-if="errors.has('password confirmation')">
								<p class="text-danger">{{ errors.first('password confirmation') }}</p>
							</div>
						</b-form-group>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="submit" variant="primary" class="btn-min-width float-left">
						Save
					</b-button>
				</template>
			</b-card>
		</div>
	</form>
</template>
<script>
	import api from '@/utils/api';
	import ResponseAlert from '@/components/response_alert';
	export default {
		name: 'ChangePassword',
		components: {
			ResponseAlert,
		},
		data() {
			return {
				models: {}
			}
		},
		methods: {
			save() {
				let _ = this
				_.$validator.validate().then(valid => {
					if (valid) {
						_.$axios
						.put(`${api.profile}/change_password`, _.models)
						.then(res => {
							_.$refs.response.setResponse(res.data.message, 'success');
							location.reload();
						}).catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					}else{
						window.scrollTo(0,0);
					}
				});
			}
		}
	}
</script>